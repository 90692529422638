<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdatetime, sumdate } from '@/plugins/filters'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsService({
      serve_id: route.value.query.id.split(','),
      lang: i18n.locale,
    }).then(res => {
      savePDF(res)
    })

    const headersCourse = [
      i18n.t('code'),
      i18n.t('use_the_course_service'),
      i18n.t('receipt'),
      i18n.t('buyer'),
      i18n.t('users'),
      i18n.t('date'),
    ]
    const headersDrug = [
      i18n.t('code'),
      i18n.t('service_used'),
      i18n.t('detail'),
    ]
    const result2 = []

    const generateDataCourse = data => {
      const result = []

      data.forEach(item => {
        result.push([
          item.course_id,
          item.course_name,
          item.order_id,
          item.customer_fullname,
          item.customer_serving,
          sumdatetime(item.serving_date),
        ])
        result2.push(`${item.course_name} : ${item.course_detail} \n`)
      })

      return result
    }
    const generateDataDrug = data => {
      const result = []
      data.forEach(item => {
        result.push([
          item.servingdetail_drug_id,
          `${item.servingdetail_drug_name}*${item.servingdetail_drug_amount} (${item.servingdetail_drug_unit})`,
          { content: String(result2).replaceAll(',', ''), rowSpan: data.length },
        ])
      })

      return result
    }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: `ใบรายละเอียดการใช้บริการ - ${data[0].serve_code}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 5
      let total = 10

      for (let i = 0; i < data.length; i++) {
        dataTableList.value = data[i].check_list
        doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

        // Start Address Shop block\
        doc.setFontSize(fontTitle.value)
        doc.setFillColor('#212121')
        doc.text(`${data[i].shop_name} ${data[i].shop_nature}`, 40, total)
        doc.setFont('TH-Niramit', 'normal')
        total += spacer
        doc.text(`${data[i].shop_address} ${data[i].shop_district}`, 40, total)
        total += spacer
        doc.setFontSize(16)
        doc.text('ใบแสดงรายการบริการ', 205, total - 5, null, null, 'right')
        doc.text(`${i18n.t('a_number')} ${data[i].serve_code}`, 205, total + 2, null, null, 'right')
        doc.setFontSize(fontTitle.value)
        doc.text(`${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`, 40, total)
        total += spacer
        doc.text(`เบอร์โทรศัพท์ ${data[i].shop_tel} ${i18n.t('email')} ${data[i].shop_email}`, 40, total)
        total += spacer
        doc.text(`${i18n.t('numberLicenses')} ${data[i].shop_license} ${i18n.t('tax_id')} ${data[i].shop_tax}`, 40, total)
        total += spacer
        doc.setLineWidth(0.8)
        doc.setDrawColor('#1E88E5')
        doc.line(61, 34, 205, 34)
        doc.setDrawColor('#212121')
        doc.line(4, 34, 60, 34)
        total += spacer
        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${i18n.t('course_list')}`, 5, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(`${i18n.t('date')} : ${sumdate(data[i].serve_date)}`, 205, total, null, null, 'right')
        total += spacer - 4

        doc.autoTable({
          theme: 'grid',
          styles: {
            font: 'TH-Niramit',
            fontStyle: 'normal',
            textColor: '#212121',
            fontSize: fontBody.value,
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            textColor: '#fff',
            fillColor: '#1E88E5',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontTitle.value,
            halign: 'center',
          },
          columnStyles: {
            0: { halign: 'center', cellWidth: 18 },
            1: { halign: 'left', fillColor: '#F5F5F5' },
            2: { halign: 'center', cellWidth: 20 },
            3: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 40 },
            4: { halign: 'center', cellWidth: 40 },
            5: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 32 },
          },
          startY: total,
          margin: { left: 5, right: 5, bottom: 40 },
          head: [headersCourse],
          body: generateDataCourse(data[i].course_list || []),

          didDrawPage(datax) {
            total = datax.cursor.y
          },
        })
        total += spacer * 1.5
        doc.setFont('TH-Niramit', 'bold')
        doc.text(i18n.t('list_drug_equipment'), 5, total)
        doc.autoTable({
          theme: 'grid',
          styles: {
            font: 'TH-Niramit',
            fontStyle: 'normal',
            textColor: '#212121',
            fontSize: fontBody.value,
            halign: 'left',
          },
          headStyles: {
            textColor: '#fff',
            fillColor: '#1E88E5',
            fontStyle: 'bold',
            lineWidth: 0.1,
            fontSize: fontTitle.value,
            halign: 'center',
          },
          columnStyles: {
            0: { halign: 'center', cellWidth: 18 },
            1: { halign: 'left', fillColor: '#F5F5F5', cellWidth: 70 },
            2: { halign: 'left' },
          },
          startY: total + 2,
          margin: {
            left: 5, right: 5, bottom: 10,
          },
          head: [headersDrug],
          body: generateDataDrug(data[i].drug_list || []),

          didDrawPage(datax) {
            total = datax.cursor.y
          },

        })
        if (total > 250) {
          doc.addPage()
          total = 10
        }
        total += spacer
        doc.setFont('TH-Niramit', 'bold')
        doc.text('หมายเหตุ : ', 5, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(data[i].serve_comment || '', 23, total)
        total += spacer * 2
        doc.text('.......................................................', 45.5, 270, null, null, 'center')
        doc.text('.......................................................', 105, 270, null, null, 'center')
        doc.text('.......................................................', 164.5, 270, null, null, 'center')
        doc.text('(                                    )', 45.5, 270 + spacer, null, null, 'center')
        doc.text('(                                    )', 105, 270 + spacer, null, null, 'center')
        doc.text(`(${data[i].user_fullname})`, 164.5, 270 + spacer, null, null, 'center')
        doc.text(`${i18n.t('staff')}`, 45.5, 270 + spacer * 2, null, null, 'center')
        doc.text(`${i18n.t('users')}`, 105, 270 + spacer * 2, null, null, 'center')
        doc.text(`${i18n.t('doctor')}`, 164.5, 270 + spacer * 2, null, null, 'center')
        doc.text(`${i18n.t('date')}............./............./.............`, 45.5, 270 + spacer * 3, null, null, 'center')
        doc.text(`${i18n.t('date')}............./............./.............`, 105, 270 + spacer * 3, null, null, 'center')
        doc.text(`${i18n.t('date')}............./............./.............`, 164.5, 270 + spacer * 3, null, null, 'center')
      }

      frame.value = doc.output('datauristring', { filename: 'test.pdf' })
    }

    return { savePDF, frame }
  },
}
</script>
